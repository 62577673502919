* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
img {
  max-width: 100%;
  height: auto;
}
.App {
  min-height: 100vh;
  width: auto;
  max-width: 1900px;
  font-family: "Reem Kufi", sans-serif !important;
}
html,
body {
  max-width: 100%;
  overflow-x: hidden;
  background-color: #fbfbfd;
}
.home {
  background-color: #6118f3;
  width: 100%;
  padding-bottom: 5%;
}
.chessboard-background {
  background-image: linear-gradient(to right, #4f4f4f53 1px, transparent 0.5px),
    linear-gradient(to bottom, #4f4f4f53 1px, transparent 0.5px);
  background-size: 30px 30px;
}
nav {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 6em;
  margin-left: 6%;
  margin-right: 6%;
}
.navbar-links-container,
.navbar-links-container-footer {
  margin-left: auto;
}
.navbar-links-container a {
  margin-left: 3rem;
  text-decoration: none;
  color: #f6f6f6;
  font-size: 1rem;
  cursor: pointer;
}
.navbar-links-container-footer a {
  margin-left: 3rem;
  text-decoration: none;
  color: #f6f6f6;
  font-size: 1rem;
  cursor: pointer;
}
.nav-logo-container-1 {
  font-weight: bold;
  color: white;
  font-size: 1.5em;
}
.sub-heading {
  font-weight: normal;
  color: #c8c6c6;
}
.navbar-toggle {
  display: none;
  cursor: pointer;
  font-size: 1.5rem;
  color: #f6f6f6;
}
.content,
.coursePreview {
  display: flex;
  justify-content: space-between;
  padding-left: 6%;
  padding-right: 6%;
  align-items: center;
}
.welcome-message {
  width: 35rem;
  height: 22rem;
}
.course-welcome-message {
  margin-top: 2.5%;
  width: 40em;
  height: 20em;
}
.head-line,
.course-head-line {
  width: auto;
  height: 5em;
  color: white;
  font-size: 2.5em;
  font-weight: bold;
  text-align: left;
  line-height: 1.6em;
  display: flex;
  align-items: flex-start;
}
.course-head-line {
  height: 2em;
}
.sub-head {
  font-size: 1.2em;
  color: white;
  display: flex;
  line-height: 5rem;
}
.course-sub-head {
  font-size: 1.45em;
  color: white;
  display: flex;
  line-height: 2.5rem;
}
.welcome-message-buttons {
  width: 20em;
  height: 3em;
}
.btn-outline-1 {
  background-color: white;
  border: none;
  color: #6118f3;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin: 4px 2px;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: -3px 3px 0px 0px #ebd408, -3px 3px 0px 0.5px white;
  transition: all 0.2s ease;
}
.btn-outline-1:active {
  background-color: #00796b;
  box-shadow: 0 2px #666666;
  transform: translateY(4px);
  transform: translateX(4px);
}
.btn-outline-2 {
  margin-left: 7%;
  background-color: #6118f3;
  border-color: white;
  color: white;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  border: 1px solid white;
  border-radius: 8px;
  box-shadow: -3px 3px 0px 0px #6118f3, -4px 4px 0px 0.5px #ebd408;
  transition: all 0.2s ease;
}
.btn-outline-2:active {
  background-color: #1c0079;
  box-shadow: 0 2px #666;
  transform: translateY(4px);
  transform: translateX(4px);
}
.cards-first-set {
  margin-top: 5%;
  display: flex;
  gap: 3em;
}
.cards-second-set {
  display: flex;
  gap: 3em;
}
.card-1 {
  width: 223px;
  height: 223px;
  background-color: #ffcb11;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  position: relative;
  font-style: oblique;
  font-size: 22px;
}
.card-2 {
  width: 223px;
  height: 223px;
  margin-top: 3rem;
  background-color: #ffcb11;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border-radius: 4px;
  position: relative;
  font-style: oblique;
  font-size: 22px;
}
.pin1,
.pin2 {
  border-radius: 50%;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  box-shadow: 0px 0.1px 0px 0px #737070;
}
.pin1 {
  background-color: #cca20e;
  width: 17px;
  height: 17px;
  top: 0.5rem;
  left: 0.5rem;
}
.pin2 {
  background-color: #cca20e;
  width: 12px;
  height: 12px;
  top: 6px;
  left: 14px;
}
.methodology,
.about,
.achive {
  width: auto;
  text-align: center;
  line-height: 2em;
  border-radius: 30px;
  border: 0.5px solid black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.methodology,
.achive {
  margin: 6% 8% 6% 8%;
  height: 21em;
}
.achive {
  height: auto;
  align-items: start;
}
.about {
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
  height: 280px;
}
.methodology-heading,
.about-heading {
  font-size: 2.5em;
  font-weight: bold;
  width: auto;
  height: 1.2em;
}
.achive-heading {
  font-size: 2em;
  font-weight: bold;
  width: auto;
  height: 1.2em;
  margin: 4% 0% 2% 4%;
}
.methodology-description,
.about-description {
  font-size: 20px;
  line-height: 1.8em;
  width: 25em;
  height: 4em;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
}
.achive-description {
  margin: 0% 0% 4% 4%;
  text-align: left;
  line-height: 2.5em;
}
.achive-bullets {
  font-size: 1.3em;
}
.achievements-columns {
  display: flex;
  justify-content: space-between;
  margin-left: 6%;
  line-height: 3em;
}
.achievements-column {
  width: 37em;
}
.left-column {
  text-align: left;
  margin-bottom: 4%;
}
.right-column {
  text-align: left;
  margin-bottom: 4%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-right: 10%;
}
.methodology-description {
  width: 38em;
  height: 4.5em;
}
.about-description {
  padding-top: 3%;
  width: 1036px;
  height: 108px;
}
.methodology-button {
  background-color: #fcfcfd;
  border-color: black;
  color: rgb(7, 7, 7);
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  font-weight: bold;
  margin-top: 4%;
  width: auto;
  height: 45px;
  cursor: pointer;
  border: 1px solid black;
  border-radius: 8px;
  box-shadow: -3px 3px 0px 0px #fcfcfd, -3px 3px 0px 0.5px black;
  transition: all 0.2s ease;
  display: flex;
}
.methodology-button:active {
  background-color: #6118f3;
  box-shadow: 0 2px #666;
  transform: translateY(4px);
  transform: translateX(4px);
}
.methodology-button a {
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.courses,
.register {
  margin-left: 6%;
  margin-right: 6%;
  width: auto;
  border-radius: 20px;
  background-color: #6118f3;
}
.courses {
  height: 58.5em;
}
.register {
  margin-top: 6%;
  height: 770px;
}
.courses-top-line,
.register-heading,
.feedback-heading,
.course-overview-heading {
  padding-top: 5%;
  margin-left: 5%;
  color: white;
  font-size: 30px;
  font-weight: bold;
  text-align: left;
  line-height: 1.5em;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.feedback-heading {
  padding-top: 3%;
}
.course-overview-heading {
  padding-top: 2%;
}
s {
  font-style: oblique;
}
.courses-top-line {
  width: auto;
  height: 84px;
  margin-right: 5%;
}
.courses-heading {
  width: 20em;
  height: 3em;
}
.select-wrapper {
  position: relative;
  display: inline-block;
  width: 100%;
}
.select-wrapper select {
  width: 100%;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: 1px solid #ccc;
}
.courses-top-line .select-wrapper {
  width: auto;
}
.courses-top-line .select-wrapper::after {
  top: 32%;
  right: 0.5em;
}
.course-filter {
  width: 10em;
  height: 2.5em;
  border-radius: 6px;
  font-size: 12px;
  cursor: pointer;
}
.select-wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 1em;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 5px solid black;
  pointer-events: none;
  transform: translateY(-50%);
}
.course-count {
  font-size: 16px;
  color: white;
  font-weight: normal;
}
.register-heading {
  width: 14em;
  height: 3em;
}
.enroll-cards {
  margin-left: 5%;
  align-items: center;
  margin-top: 5%;
  display: flex;
  gap: 2em;
}
.contact,
.register-form {
  width: 35em;
  height: 35em;
  background-color: white;
  border-radius: 20px;
  margin-right: 5%;
}
.contact-heading,
.register-form-heading {
  margin-top: 5%;
  font-size: 24px;
  font-weight: bold;
  color: #000000;
  margin-left: 8%;
}
.contact-form-group,
.form-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5em;
  margin-top: 5%;
  margin-left: 8%;
  margin-right: 8%;
}
.form-group label {
  margin-bottom: 0.3em;
}
.form-group input,
select {
  padding: 0.5em;
  font-size: 1em;
  width: auto;
  border-radius: 8px;
  height: 42px;
  border: 1px solid #dedddd;
}
.form-group input:focus,
select:focus {
  border-color: #6118f3;
  outline: none;
}
.register-btn {
  background-color: #6118f3;
  border: none;
  color: #ffffff;
  text-align: center;
  text-decoration: none;
  display: block;
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  border-radius: 8px;
  box-shadow: -3px 3px 0px 0px #ebd408, -3px 3px 0px 0.5px white;
  transition: all 0.2s ease;
  width: auto;
  height: 3em;
  margin-top: 1%;
}
.register-btn:active {
  background-color: #00796b;
  box-shadow: 0 2px #666666;
  transform: translateY(4px);
  transform: translateX(4px);
}
.submit-message {
  margin-top: 3%;
  text-align: center;
  color: #1c0079;
}
.contact-form-group {
  display: flex;
  flex-direction: row;
}
.contact-icon {
  flex-shrink: 0;
}
.contact-icon img {
  margin-right: 20px;
}
#mail img {
  width: 18px;
  height: 15px;
}
#call img {
  width: 17px;
  height: 17px;
}
#location img {
  width: 16px;
  height: 20px;
}
.contact-details label {
  display: block;
}
.contact-details span {
  font-weight: bold;
  line-height: 28px;
}
.grid-container {
  margin: 5% 5% 0% 5%;
  height: 75%;
  justify-content: center;
  align-items: center;
}
.grid-item {
  background-color: white;
  text-align: center;
  border-radius: 20px;
  width: 20.5em !important;
  height: 19.5em;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  text-decoration: none;
  color: inherit;
  margin-right: 2.5em;
  margin-bottom: 2.5em;
}
.pagination {
  display: flex;
  justify-content: space-between;
  margin: 0em 5em 0em 5em;
}
.pagination-buttons {
  display: flex;
  flex-direction: row;
}
.pagination button {
  margin: 0 10px;
  padding: 10px 15px;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  height: 30px;
  width: 30px;
  color: #1c0079;
  font-weight: bolder;
}
.pagination button:disabled {
  background-color: #a9a6a6;
  cursor: not-allowed;
}
.pagination button:not(:disabled):hover {
  background-color: #ccc;
}
.courses-bottom {
  color: white;
  align-items: center;
  justify-content: center;
  display: flex;
}
.course-image-border {
  width: 20.5em;
  height: 9.8em;
  background-color: #ffcb11;
  border-radius: 20px 20px 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.course-image-border img {
  width: 19.5em;
  height: 9em;
}
.course-details {
  padding: 5px 20px 0px 20px;
  text-align: left;
}
.course-name {
  font-size: 1.2rem;
  font-weight: bold;
  margin-top: 0.6em;
}
.course-duration,
.course-duration-preview {
  display: flex;
  justify-content: space-between;
  margin-top: 4%;
  font-size: 0.9em;
}
.course-duration-preview-list {
  display: none;
}
.course-duration-preview {
  color: white;
}
.course-duration {
  margin-top: 5%;
}
.course-duration span {
  font-weight: bold;
}
.course-duration-preview span {
  font-weight: bold;
}
.course-mode {
  font-size: 0.75em;
  margin-top: 1%;
}
.course-cost {
  margin-top: 8%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.course-cost-preview {
  margin: 5% 0% 5% 0%;
  display: flex;
  justify-content: space-between;
  color: white;
  font-size: 1.7em;
}
.course-cost-preview span {
  font-weight: bolder;
}
.course-cost span {
  font-weight: bold;
  font-size: 18px;
}
.course-cost button {
  width: 4.8em;
  height: 2.5em;
  border-radius: 8px;
  background-color: white;
}
.course-cost button {
  cursor: pointer;
}
.course-overview {
  width: auto;
  height: auto;
  background-color: #efe8fe;
}
.content-section {
  margin-bottom: -0.2em;
}
.course-content {
  font-family: Arial, sans-serif;
  margin: 2% 8% 0% 8%;
  padding-bottom: 5%;
}
.content-headline {
  font-size: 1em;
  font-weight: bold;
  cursor: pointer;
  background: none;
  border: #ceb7fb solid 2px;
  color: black;
  text-align: left;
  padding: 1em;
  width: 100%;
  background-color: white;
  border-radius: 0.2em;
  margin-top: -0.2em;
  display: flex;
  justify-content: space-between;
}
.collapse-symbol,
.collapse-headline {
  margin-left: 1em;
}
.collapse {
  margin-left: 3.5em;
}
.sub-content {
  font-size: 1em;
  cursor: pointer;
  background: none;
  border: #ceb7fb solid 2px;
  color: black;
  text-align: left;
  padding: 1em;
  width: 100%;
  background-color: white;
  border-radius: 0.2em;
  margin-top: -0.2em;
}
.course-sub-heading {
  font-size: 1em;
  margin-bottom: 1em;
  margin-top: 1em;
  color: black;
  font-weight: bold;
}
.sub-list {
  list-style-type: disc;
  margin-left: 1.5em;
  line-height: 2em;
}
.sub-item {
  margin-bottom: 5px;
}
.expand-button {
  margin-right: 9%;
  padding-bottom: 0.4em;
}
.expand-button button {
  width: 6em;
  height: 2em;
  background-color: #ffffff;
  border: 0.1em solid #ffffff;
  border-radius: 0.4em;
  cursor: pointer;
}
.topics {
  font-weight: normal;
}
.assurance {
  display: flex;
  font-weight: bold;
  font-size: xx-large;
  margin: 2em;
  align-items: center;
  justify-content: center;
  position: relative;
}
.assurance img {
  margin-right: 1em;
  margin-left: 0;
}
.feedback {
  width: auto;
  height: 450px;
  background-color: #efe8fe;
}
.feedback-heading,
.course-overview-heading {
  margin-left: 8%;
  color: #080808;
}
iframe {
  border-radius: 1em;
}
.feedback-comments {
  margin-left: 8%;
  margin-right: 8%;
  align-items: center;
  justify-content: center;
  margin-top: 2%;
  display: flex;
}
.feedback-cards {
  height: 270px;
  background-color: white;
  border-radius: 3px;
  border: 2px solid #ceb7fb;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s;
  margin-bottom: 1.2em;
}
.feedback-slider {
  padding-left: 1.5em;
  padding-right: 1.5em;
}
.feedback-cards img {
  margin: 8%;
}
.feedback-description {
  width: 224px;
  height: 80px;
  margin-left: 10%;
  font-size: 12px;
  line-height: 20px;
}
.feedback-description span {
  font-weight: bold;
}
.feedback-footer {
  margin-top: 15%;
  margin-left: 10%;
}
.feedback-cards label {
  margin-top: 5%;
  margin-left: 10%;
  font-size: 9px;
}
.footer {
  margin-top: 8%;
  width: auto;
  height: 178px;
  background-color: #2c0b6d;
}
.footer-bottom {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 6%;
  padding-right: 6%;
  margin-top: 1%;
  align-items: center;
}
.copyright {
  font-size: 12px;
  color: rgb(181, 180, 180);
}
.social-icons {
  width: 120px;
  height: 25.49px;
}
.social-icons a {
  margin-right: 10%;
}
.far-top {
  display: none;
}
input[type="date"]::-webkit-calendar-picker-indicator {
  opacity: 0;
}
input[type="date"] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  position: relative;
  width: 100%;
  padding: 10px;
  font-size: 16px;
  background: url("/public/assets/images/calendar.svg") no-repeat right 10px
    center;
  background-size: 24px;
}
input[type="date"]::before {
  content: "";
  position: absolute;
  top: 50%;
  right: 10px;
  transform: translateY(-50%);
  width: 24px;
  height: 24px;
  pointer-events: none;
}
input[type="date"].placeholder::before {
  content: attr(data-placeholder);
  position: absolute;
  left: 12px;
  top: 56%;
  color: #898787;
  pointer-events: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
input[type="date"].placeholder {
  color: transparent;
}
.contact a {
  text-decoration: none;
}
@media (max-width: 768px) {
  .chessboard-background {
    height: fit-content;
  }
  nav {
    min-height: 80px;
    margin-left: 3%;
  }
  .navbar-links-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 5.6em;
    left: 0;
    overflow: hidden;
    max-height: 0;
    transition: max-height 1s ease-in-out;
    visibility: hidden;
    opacity: 0;
  }
  .footer-nav {
    flex-direction: column;
    align-items: flex-start;
    margin: 0 1em;
    gap: 1.5em;
    padding-top: 1.5em;
  }
  .navbar-links-container-footer {
    display: flex;
    flex-direction: column;
    line-height: 2em;
    margin: 0;
    align-items: flex-start;
  }
  .navbar-links-container-footer a {
    margin: 0;
    color: #a8a6a6;
    font-size: 0.8em;
  }
  .nav-logo-container-1 {
    font-size: 1.2em;
  }
  .navbar-links-container.open {
    display: flex;
    max-height: 100%;
    visibility: visible;
    opacity: 1;
    height: 100vh;
  }
  .navbar-toggle {
    display: block;
    color: white;
  }
  .content.hide {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s 0.1s, opacity 0.1s linear;
  }
  @keyframes slideIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  .navbar-links-container a {
    margin: 1rem 0;
    font-size: 0.9rem;
    opacity: 0;
    animation: slideIn 0.5s forwards;
    animation-delay: 0.5s;
  }
  .navbar-links-container.open a:nth-child(1) {
    animation-delay: 0.5s;
  }
  .navbar-links-container.open a:nth-child(2) {
    animation-delay: 0.6s;
  }
  .navbar-links-container.open a:nth-child(3) {
    animation-delay: 0.7s;
  }
  .navbar-links-container.open a:nth-child(4) {
    animation-delay: 0.8s;
  }
  .content {
    flex-direction: column;
    padding: 3%;
    align-items: flex-start;
  }
  .welcome-message {
    width: 100%;
    height: auto;
  }
  .head-line {
    font-size: 35px;
    line-height: 1.5;
    height: 5em;
  }
  .sub-head {
    font-size: 16px;
    line-height: 2rem;
  }
  .cards-first-set {
    margin-top: 3em;
    gap: auto;
  }
  .cards-second-set {
    margin-top: -1em;
    gap: auto;
  }
  .card-1,
  .card-2 {
    width: 8em;
    height: 8em;
  }
  .cards-size {
    margin: auto;
    width: 100%;
  }
  .methodology,
  .about {
    margin: 15% 5%;
    height: 27em;
    text-align: left;
    align-items: flex-start;
  }
  .methodology-heading {
    margin: 4%;
    height: auto;
    line-height: 1.3em;
  }
  .about-heading {
    margin: 4%;
    height: auto;
  }
  .methodology-description,
  .about-description {
    width: 100%;
    height: auto;
    text-align: left;
    padding: 4%;
    font-size: 2em;
  }
  .methodology-button {
    margin: 4%;
  }
  .courses,
  .register {
    margin: 0;
    border-radius: 0;
    height: auto;
  }
  .courses-top-line,
  .register-heading {
    margin: 5%;
    line-height: 1.2;
    flex-direction: column;
    height: 10.5em;
    align-items: flex-start;
  }
  .courses-heading {
    width: 10em;
    font-size: 1.5em;
  }
  .course-overview-heading {
    font-size: 1.5em;
  }
  .course-overview-heading {
    margin-left: 0.5em;
    margin-right: 0.5em;
  }
  .expand-button {
    margin-right: 0.5em;
    font-size: 1em;
  }
  .course-overview-heading {
    padding-top: 5%;
    padding-bottom: 5%;
  }
  .course-content {
    margin-left: 1em;
    margin-right: 1em;
  }
  .register-heading {
    width: 10em;
    font-size: 2em;
    height: auto;
  }
  .course-count {
    padding: 0;
    font-size: 0.6em;
  }
  .course-name {
    font-size: 1rem;
  }
  .enroll-cards {
    flex-direction: column;
    margin: 1em;
    gap: 1em;
  }
  .contact,
  .register-form {
    width: 100%;
    height: auto;
    margin: 0;
    border-radius: 1em;
  }
  .register-form {
    margin-bottom: 3em;
  }
  .grid-container {
    margin: 4%;
    height: auto;
  }
  .grid-item {
    width: 100%;
    height: auto;
    margin: 1rem 1rem 2rem 1rem;
  }
  .courses-bottom {
    display: none;
  }
  .course-mode {
    align-items: center;
    justify-content: center;
    font-size: 0.8em;
  }
  .course-cost-preview {
    margin: 0.5em;
  }
  .course-cost {
    margin-top: 5%;
    margin-bottom: 5%;
  }
  .pagination {
    margin-right: 1em;
    align-items: center;
    justify-content: flex-end;
    padding-bottom: 5%;
  }
  .assurance {
    flex-direction: column;
    align-items: start;
    justify-content: flex-start;
    margin: 1.5em;
    line-height: 1.5em;
    gap: 1em;
    font-size: 1.5em;
  }
  .assurance img {
    margin: 0;
    width: 2em;
    height: 2em;
  }
  .feedback-comments {
    flex-direction: row;
    margin: 0;
  }
  .feedback-slider {
    padding-left: 1em;
    padding-right: 1em;
  }
  .contact-form-group,
  .form-group {
    margin: 1.5rem;
  }
  .contact-form-group label,
  .form-group label {
    font-size: 0.9rem;
  }
  .contact-form-group input,
  .form-group input,
  select {
    font-size: 0.9rem;
  }
  select {
    max-height: 3em;
    overflow-y: auto;
  }
  iframe {
    height: 300px;
    border-radius: 0.5em;
  }
  .feedback-cards {
    width: 100%;
    margin: 0.5rem auto;
  }
  .footer {
    margin-top: 4em;
    height: 100%;
  }
  .footer-bottom {
    flex-direction: column-reverse;
    align-items: flex-start;
    padding: 2%;
    margin: 0;
    margin-top: 1.5em;
  }
  .social-icons {
    width: 100%;
    justify-content: center;
    margin-bottom: 1.5em;
  }
  .copyright {
    padding: 0.8em;
  }
  .social-icons a {
    margin: 0 1rem;
  }
  .course-welcome-message {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  .course-head-line {
    font-size: 2em;
    width: 10em;
    line-height: 1.2em;
    height: auto;
  }
  .course-sub-head {
    font-size: 1em;
    line-height: 1.5em;
    margin-top: 1em;
    margin-bottom: 1em;
    width: 20em;
  }
  .course-duration-preview-list {
    font-size: 0.8em;
    line-height: 2em;
    display: contents;
    color: white;
    margin-left: 2em;
  }
  .course-duration-preview-list li {
    margin-left: 1em;
  }
  .course-duration-preview-list span {
    font-weight: bold;
  }
  .course-duration-preview {
    display: none;
  }
  .coursePreview {
    padding: 0;
    padding-left: 3%;
    padding-right: 3%;
    flex-direction: column;
    justify-content: space-between;
    align-items: start;
  }
  .coursePreview img {
    padding-top: 2em;
  }
  .achive-heading {
    font-size: 1.6em;
    margin: 0.8em;
    height: auto;
    text-align: left;
  }
  .achievements-columns {
    flex-direction: column;
    line-height: 2em;
    margin-left: 2em;
  }
  .left-column {
    margin: 0;
    width: auto;
  }
  .right-column {
    flex-direction: column;
    width: auto;
    margin: 0;
    margin-bottom: 1.5em;
  }
  .achive-bullets {
    font-size: 1em;
  }
  .achive {
    margin: 8% 3% 8% 3%;
  }
  .content-headline {
    flex-direction: column;
    gap: 0.5em;
  }
  .collapse {
    margin-left: 2.5em;
  }
  .collapse-module {
    display: flex;
  }
  .collapse-headline {
    height: auto;
    width: auto;
  }
  .collapse-symbol,
  .collapse-headline {
    margin-left: 0.5em;
  }
  .topics {
    display: flex;
    margin-left: 3em;
  }
  .copyright {
    display: flex;
    align-items: center;
    gap: 10em;
    justify-content: space-between;
  }
  .far-top {
    display: block;
    justify-content: end;
    align-items: end;
  }
}
@media (max-width: 480px) {
  .navbar-links-container a {
    font-size: 1.5rem;
  }
  .head-line {
    font-size: 30px;
    line-height: 1.5em;
    height: 100%;
  }
  .sub-head {
    font-size: 1.2em;
    height: 4em;
    margin-top: 1em;
  }
  .methodology-heading,
  .about-heading {
    font-size: 32px;
  }
  .methodology-description,
  .about-description {
    font-size: 1.2em;
  }
  .courses-top-line {
    font-size: 1.3em;
  }
  .feedback {
    height: 500px;
  }
  .feedback-heading {
    font-size: 1.7em;
    margin: 1em;
    padding-top: 2em;
  }
  iframe {
    width: 100%;
  }
  .grid-container .slick-dots li button:before {
    color: white !important;
  }
  .register-btn {
    font-size: 14px;
  }
  .grid-container {
    grid-template-columns: 1fr;
  }
  .grid-item {
    margin: 1rem 1rem 2rem 1rem;
    width: 19.5em !important;
  }
  .course-image-border {
    width: 19.5em;
    height: 9.8em;
  }
  .course-image-border img {
    width: 18.5em;
    height: 9em;
  }
  .contact-form-group,
  .form-group {
    margin: 1rem;
  }
  .feedback-description {
    font-size: 10px;
    width: auto;
    margin-right: 10%;
  }
  .feedback-footer {
    margin-top: 10%;
  }
  .feedback-footer span {
    font-size: 12px;
  }
  .contact-heading,
  .register-form-heading {
    margin-left: 1rem;
  }
  .select-wrapper {
    display: flex;
    justify-content: space-between;
    gap: 5em;
    align-items: center;
  }
  .course-count {
    width: 100%;
  }
  .courses-top-line .select-wrapper::after {
    right: 68%;
    top: 50%;
  }
  .filter-size {
    width: 100%;
  }
  .contact-icon img {
    margin-right: 10px;
  }
  .copyright {
    font-size: 10px;
  }
  .social-icons a {
    margin: 0.5rem;
  }
}
